<template>
  <div id="page-user-view" class="p-6">
    <div class="vx-col flex-1" id="account-info-col-1">
      <form>
        <div class="vx-row">
          <div class="vx-col w-full lg:w-1/2">
            <h2>{{$t('Payment Accounts')}}</h2>
          </div>
          <div class="vx-col w-full lg:w-1/2 items-end">
            <div class="flex justify-between">
              <span class="font-semibold"></span>
              <span class="font-medium text-primary cursor-pointer">
                <vs-button
                  @click="newPaymentAccounts()"
                  color="primary"
                  icon-pack="feather"
                  icon="icon-plus"
                  size="large"
                >
                </vs-button>
              </span>
            </div>
          </div>
        </div>
        <shipblu-table
          :sst="true"
          multiple
          v-model="selected"
          pagination
          :max-items="maximumItems"
          :data="paymentAccounts"
          @search="handleSearch"
          @keyup.enter.native="handleSearch"
          search
          :tableLoader="tableLoader"
        >
          <div
            slot="header"
            class="flex flex-wrap-reverse items-center flex-grow justify-between"
          >
          </div>
          <template slot="thead">
            <shipblu-th>{{$t('Account Name')}}</shipblu-th>
            <shipblu-th>{{$t('Account Number')}}</shipblu-th>
          </template>

          <template slot-scope="{ data }">
            <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <shipblu-td :data="data[indextr].account_name">
                {{ data[indextr].name }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].account_number">
                {{ data[indextr].number}}
              </shipblu-td>
            </shipblu-tr>
          </template>
        </shipblu-table>
      </form>
    </div>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeModal"
      @cancel="closeModal"
      :active.sync="paymentAccountModal"
      :title="$t('Add Payment Account')"
      :buttons-hidden="true" autocomplete="nofill">
      <div class="vx-row">
        <div class="vx-col w-1/2">
          <vs-input v-validate="'required'" name="name" :label-placeholder="$t('Name') + '*'" v-model="paymentAccount.name" class="mt-8 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first('name') }}</span>
        </div>
        <div class="vx-col w-1/2">
          <vs-input v-validate="'required|numeric'" name="number" :label-placeholder="$t('Number') + '*'" v-model="paymentAccount.number" class="mt-8 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('number')">{{ errors.first('number') }}</span>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-4">
        <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
        <button @click="addPaymentAccounts" class="active-btn btn">{{ $t('Add') }}</button>
      </div>
    </shipblu-prompt>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
  </div>
</template>
<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import common  from '@/assets/utils/common'
import i18nData from '../../i18n/i18nData.js'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluPrompt from '../../layouts/components/ShipBluPrompt.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      totalRows: 0,
      offset: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      selected: [],
      paymentAccountModal: false,
      paymentAccounts: [],
      paymentAccount: {},
      searchInProgress: false,
      searchedValue: ' ',
      tableLoader: false,
      searchVal: ''
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadPaymentAccounts()
    }
  },
  methods: {
    closeModal () {
      this.paymentAccountModal = false
      this.loadPaymentAccounts()
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadPaymentAccountsSearch)
    },
    loadPaymentAccountsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadPaymentAccounts()
    },
    loadPaymentAccounts () {
      this.searchInProgress = true
      this.tableLoader = true
      const query = `?offset=${this.offset}&search=${this.searchVal}&limit=${this.maximumItems}`
      sendRequest(true, false, this, `api/v1/accounting/payment-accounts/${query}`, 'get', null, true, 
        (response) => {
          this.paymentAccounts = response.data.results
          this.tableLoader = false
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
        }
      )
      this.searchInProgress = false
    },
    addPaymentAccounts () {
      this.$validator.validateAll().then(result => {
        if (result) {
          sendRequest(false, false, this, 'api/v1/accounting/payment-accounts/', 'post', this.paymentAccount, true,
            () => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Payment Account'] + i18nData[this.$i18n.locale][' has been created successfully!'],
                position: 'top-center'
              })
              this.paymentAccountModal = false
              this.loadPaymentAccounts()
            }
          )
          
        }
      })
    },
    newPaymentAccounts () {
      this.paymentAccount = {
        name: '',
        number: ''
      }
      this.paymentAccountModal = true
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPrompt,
    ShipbluPagination
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadPaymentAccounts()
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>